import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Usage Library`}</h2>
    <p>{`Legion design Library are separated in multiple project, the first step is you can make sure was invited to you project file from `}<a parentName="p" {...{
        "href": "/design/getting-started/for-telkom-employee#access-request"
      }}>{`Request Form`}</a>{`, then chek the available library for used from table of list library or the project you assigned. And this is step to select and used the design library before you started to designing.`}</p>
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/create-screen/design-guideline-1-create-screen.svg",
          "alt": "create screen"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<p parentName="div" {...{
          "className": "text-xl font-bold mb-2"
        }}>{`1. Create Screen`}</p>{`
    `}<p parentName="div">{`
      Once the library is activated, start by creating a screen/frame for the specific platform screen size or by using the shortcut Click [A]. After that, don't forget to apply the grid style that matches the screen size from the variables in your frame.
    `}</p>{`
  `}</div>
    </div>
    <br />
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/create-screen/design-guideline-2-usage-ui-kit-components.svg",
          "alt": "usage ui kit components"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<p parentName="div" {...{
          "className": "text-xl font-bold mb-2"
        }}>{`2. Usage UI Kit Components`}</p>{`
    `}<p parentName="div">{`
      To start usage component from UI Kit, you can select resource menu and find components or from assets tab, then usage your component needed with drag and drop component to your screen, and don't miss it to view guideline component usage in [Components Page](/components/overview)
    `}</p>{`
  `}</div>
    </div>
    <br />
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/create-screen/design-guideline-3-usage-ui-variables.svg",
          "alt": "usage ui variables"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<p parentName="div" {...{
          "className": "text-xl font-bold mb-2"
        }}>{`3. Usage UI Variables`}</p>{`
    `}<p parentName="div">{`
      After utilizing the components, proceed with designing the screen by applying all branding properties from variables (such as Logo, Colors, Typography, etc). For consistency, ensure to use Autolayout for section content and implements values from variables like Spacing, Elevation, and Radius.
    `}</p>{`
  `}</div>
    </div>
    <br />
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/create-screen/design-guideline-4-craft-more-design-screen.svg",
          "alt": "craft more design screen"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<p parentName="div" {...{
          "className": "text-xl font-bold mb-2"
        }}>{`4. Craft more Design Screen`}</p>{`
    `}<p parentName="div">{`
      Finished your design backlog with combined usage foundation, components library and [Recommended Plugin Recommendations](#recommended-figma-plugins). But if you need annotation or GUI component you can activate Helpers Components Library, and for not available component you can contact Design Maintainer Team to create request components. Or for common flow you can use Pattern Library. 
    `}</p>{`
  `}</div>
    </div>
    <br />
    <InlineNotification mdxType="InlineNotification">
      <p><strong parentName="p">{`Create new Library:`}</strong>{` If your tribe / squad assigned not available design library, you can create and publish your tribe / squad theme in `}<a parentName="p" {...{
          "href": "/design/getting-started/for-community-external#create-library"
        }}>{`Following Steps`}</a>{` with usage Legion UI Library - Internal. For website platform if you want to create themes for development you can contact core team for assess your foundation before register to legion multi theme.`}</p>
    </InlineNotification>
    <div className="divi" />
    <h2>{`Recommended Figma Plugins`}</h2>
    <p>{`Some recommended plugins to simplify your workflow, task, accessibility checking, and design quality:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/1377553084101217769/legion-tokens-manager"
        }}><strong parentName="a">{`Legion Tokens Manager`}</strong></a>{`: A tool to manage design tokens like colors and fonts, ensuring consistency across projects.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/929607085343688745/color-shades"
        }}><strong parentName="a">{`Color Shades`}</strong></a>{`: A tool to createa and adjust color shades for a consistent and harmonious design.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/1298692189910272274/local-print"
        }}><strong parentName="a">{`Local Print`}</strong></a>{`: A feature to quickly print design assets for reviews or presentations.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/931280467863251825/adee-comprehensive-accessibility-tool"
        }}><strong parentName="a">{`Adee Comprehensive Accessibility Tool`}</strong></a>{`: Test color contrast, simulate 8 color blind simulations, and test touch target sizes to ensure they meet the various device standards.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/732603254453395948/stark-contrast-accessibility-tools"
        }}><strong parentName="a">{`Stark - Contrast & Accessibility Tools`}</strong></a>{`: Contrast Checker, Focus Order, Alt-Text Annotations, Vision Simulator, and more, all in one place.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/798826066406007173/ditto-manage-copy-from-design-to-production"
        }}><strong parentName="a">{`Ditto`}</strong></a>{`: Single source of truth for everyone touching copy, from writers to designers to engineers.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      